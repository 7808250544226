
.App-logo {
  height: 60vmin;
  pointer-events: none;
}

.MUI-Text {
  font-size: 7vh;
  font-family: "Permanent Marker";
  color: #ffffff;
}

.Icon {
  margin: 0px 10px;
}

@font-face {
  font-family: "Permanent Marker";
  font-weight: normal;
  src: url("fonts/permanent-marker/PermanentMarker-Regular.ttf") format("truetype");
}
